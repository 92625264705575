.footer {
  z-index: 150;
  height: 300px;
  width: 100%;
  display: flex;
  margin-top: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0176a8;
  position: relative;
  bottom: 0px;
  .versiontag {
    margin-top: 20px;
    color: white;
  }
  .imagescontainer {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    img {
      max-height: 150px;
    }
    .suomisportlogo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .logotext {
        padding: 0 3px;
        margin-top: 3px;
        color: white;
        margin-left: 15px;
        font-size: 12px;
        border-bottom: 1px solid white;
      }
    }
  }
}
