@import '../../styles/colors';
@import '../../styles/mixins';

.pathstatus {
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: $greyish-brown;
  padding: 10px 40px;
  transition: 0.5s;
  .sidestatus {
    height: calc(100% + 2px);
    width: 16px;
    position: absolute;
    top: -1px;
    left: -1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #e5e8e8;
    background-color: #e5e8e8;
  }
  .pathStarted {
    background-color: #dff4fa !important;
    border: 1px solid #e5e8e8;
  }
  .pathAccepted {
    background-color: #00a652 !important;
  }
  .pathArchived {
    background-color: #ccd2d2 !important;
  }
  .statustitle {
    margin: 10px 0 20px 0;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    text-align: center;

    .audit-settings-icon {
      position: absolute;
      top: 20px;
      right: 30px;
      cursor: pointer;
    }
  }
  & > button {
    width: 300px;
  }
  .hovershadow {
    transition: 0.5s;
  }
  .hovershadow:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .partscontainer {
    margin: 20px 5px 5px 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    .part {
      margin-left: 5px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 140px;
      max-height: 170px;
      overflow: visible;
      .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        overflow: visible;
        width: 80px;
        cursor: pointer;
        .statecircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 80px;
          border-radius: 80px;
          border: 1px solid lightgrey;
          margin-bottom: 10px;
          cursor: pointer;
          img {
            width: 80px;
            height: 80px;
            cursor: pointer;
          }
          user-select: none;
        }
        .circleLocked {
          background-color: #ccd2d2 !important;
          border: 1px solid #ccd2d2 !important;
          img {
            width: 67px;
            height: 67px;
          }
        }
        .name {
          text-align: center;
          overflow-y: visible;
          width: 150px;
          color: lightgrey;
          font-size: 14px;
        }
        .linkActive {
          color: $deep-sea-blue;
        }
        .newComments {
          color: #faa61a;
        }
      }
      .connector {
        margin-left: 5px;
        margin-top: 28px;
        font-size: 20px;
        overflow: visible;
        color: lightgrey;
        letter-spacing: 2px;
        user-select: none;
      }
    }
    .lockIcon {
      position: relative;
      margin: 10px 20px 70px -50px;
      width: 30px;
      height: 37px;
    }
  }
  @media only screen and (min-width: 840px) {
    .partscontainer {
      margin: 20px 5px 5px 5px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      .part {
        margin-left: 2px;
        position: relative;
        display: flex;
        flex-direction: row;
        max-height: 170px;
        overflow: visible;
        .info {
          .statecircle {
            width: 80px;
            height: 80px;
          }
        }
        .connector {
          margin-left: 5px;
          margin-top: 28px;
          letter-spacing: 2px;
        }
      }
    }
  }
  @media only screen and (max-width: 839px) and (min-width: 741px) {
    .partscontainer {
      margin: 20px 5px 5px 5px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      .part {
        margin-left: 2px;
        position: relative;
        display: flex;
        flex-direction: row;
        max-height: 170px;
        overflow: visible;
        .info {
          .statecircle {
            width: 70px;
            height: 70px;
          }
        }
        .connector {
          margin-left: 2px;
          margin-top: 28px;
          letter-spacing: 1px;
        }
      }
    }
  }
  @media only screen and (max-width: 740px) {
    .partscontainer {
      margin: 20px 5px 5px 5px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      .part {
        margin: 10px 0 10px 0;
        position: relative;
        display: flex;
        max-height: initial;
        flex-direction: column;
        align-items: center;
        height: initial;
        overflow: visible;
        .info {
          .statecircle {
            width: 80px;
            height: 80px;
          }
          .name {
            font-size: 16px !important;
          }
        }
        .connector {
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
          letter-spacing: 1px;
          margin-left: 0;
          font-size: 32px !important;
        }
      }
      .lockIcon {
        margin: 10px;
      }
    }
  }
  & > button {
    margin: 20px 0;
  }
}

.submit-modal {
  max-width: 600px;
  padding: 20px;
  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    .modal-body {
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      div {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 60px;
      }
      h1 {
        width: 100%;
        color: $deep-sea-blue;
        text-align: center;
        font-size: 20pt;
      }
      .fieldinfo {
        width: 100%;
        text-align: left;
        .fieldvalue {
          font-size: 16px;
        }
      }
      .commentfield {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        form {
          width: 100%;
          .form-group {
            label {
              color: $deep-sea-blue;
              font-size: 16px;
            }
            textarea {
              resize: none;
              width: 100%;
              height: 200px;
            }
          }
        }
      }
      .auditdropdown {
        width: 100%;
      }
    }
    .modal-footer {
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      button {
        min-width: 150px;
      }
      padding-bottom: 30px;
    }
  }
  .modalbuttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    button {
      width: 45%;
    }
  }
}

.auditsummarycontainer {
  padding: 10px;
  &:hover {
    cursor: pointer;
    .summarytext {
      filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.15));
    }
    .summaryicon {
      filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.15));
    }
  }
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .summaryicon {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    background-color: $deep-sea-blue;
    margin-right: 10px;
    color: white;
    text-align: center;
    line-height: 24px;
    margin-left: -24px;
    transition: 0.5s;
  }
  .greenicon {
    background-color: #00a652 !important;
  }
  .yellowicon {
    background-color: #faa61a !important;
  }
  .summarytext {
    transition: 0.5s;
    color: $deep-sea-blue;
  }
  .bluetext {
    color: $deep-sea-blue;
  }
  .greentext {
    color: #00a652 !important;
  }
  .yellowtext {
    color: #faa61a !important;
  }
}

.pathloading {
  height: 300px !important;
  .spinnercontainer {
    margin-top: 0px;
  }
}

.centered-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
