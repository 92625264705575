@import './../../styles/mixins';

.comment-modal {
  max-width: 600px;
  padding: 20px;
  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    .modal-body {
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      h1 {
        color: $deep-sea-blue;
      }
      .fieldinfo {
        width: 100%;
        text-align: left;
        .fieldvalue {
          font-size: 16px;
        }
      }
      .commentfield {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        form {
          width: 100%;
          .form-group {
            label {
              color: $deep-sea-blue;
              font-size: 16px;
            }
            textarea {
              resize: none;
              width: 100%;
              height: 200px;
            }
          }
        }
      }
      .auditdropdown {
        width: 100%;
      }
    }
    .modal-footer {
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      button {
        min-width: 150px;
      }
      padding-bottom: 30px;
    }
  }
}
