
.tooltip {
  max-width: 240px;
  filter: drop-shadow(0 2px 4px #B2B2B2);

  p {
    margin: 3px 5px 0 0;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
