@import './../../styles/mixins';

.pathstart-modal {
  max-width: 600px;
  padding: 20px;
  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    .modal-body {
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      h1 {
        color: $deep-sea-blue;
        text-align: center;
        width: 100%;
      }
      p {
        width: 100%;
        text-align: center;
      }
      .fieldvalue {
        font-size: 18px;
        width: 100%;
      }
      .pathstartfield {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        form {
          width: 100%;
          .form-group {
            max-width: 200px;
            label {
              color: $deep-sea-blue;
              font-size: 16px;
            }
            input {
              height: 60px;
              font-size: 24px;
            }
          }
        }
      }
    }
    .modal-footer {
      background-color: rgba(0, 0, 0, 0.1);
      .modalbuttons {
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        button {
          margin: 10px;
          min-width: 200px;
        }
      }

      padding: 20px 10px;
    }
  }
}
