@import '../../styles/colors';
.attachments-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  .attachments-container {
    max-width: 800px;
    width: 100%;
    .file-search {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 15px 15px 7px 15px;
      span {
        font-weight: 600;
        font-size: 16px;
        color: $deep-sea-blue;
        height: 22px;
        margin-bottom: 10px;
      }
      input {
        border: none;
        width: 100%;
        height: 30px;
        font-size: 20px;
        font-style: italic;
        color: #808080;
        padding: 5px 0;
        outline: none;
      }
    }
    hr {
      width: 100%;
      border: 1px solid $light-blue-grey;
      margin-bottom: 0;
      margin-top: 0;
    }
    .table-container {
      width: 100%;
      div > {
        width: 100%;
        min-height: 300px;
      }
    }
    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 30px;
      button {
        width: 200px;
      }
    }
  }
}

.attachment-modal {
  .modal-content {
    background-color: #e5e5e5;
    width: 100%;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    .modal-body {
      .field {
        padding-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        label {
          text-transform: uppercase;
          color: $deep-sea-blue;
          font-size: 16px;
        }
        input {
          border-radius: 3px;
          border: 1px solid #808080;
          height: 60px;
          width: 100%;
          padding: 15px;
          color: $greyish-brown;
          font-size: 20px;
        }
      }
      h1 {
        color: $deep-sea-blue;
        margin-bottom: 30px;
        font-weight: 400;
      }
      h2 {
        font-size: 19px;
        color: $greyish-brown;
        font-weight: bold;
        margin-bottom: 20px;
      }
      hr {
        margin: 20px 0;
        border-color: #c2c2c2;
      }
      width: 100%;
      height: 100%;
      .file-browser {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        input {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
        span {
          font-size: 14px;
          color: #808080;
          font-style: italic;
        }
        .file-added {
          color: $dark-sky-blue;
        }
        label {
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .checkbox-field-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0;
        margin-bottom: 0;
        span {
          position: relative;
          top: 0;
          border: 1px solid #c2c2c2;
        }
        label {
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 10px;
          width: calc(100% - 25px);
          font-weight: bold;
          color: #808080;
          a {
            color: #808080;
            text-decoration: underline;
          }
        }
      }
    }
    .modal-footer {
      width: 100%;
    }
    .modalbuttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      button {
        width: 45%;
        max-width: 350px;
      }
    }
  }
}

.attachments-table-header {
  &:focus {
    outline: 2px auto;
  }
}

.link-table {
  .modal-content {
    width: 100%;
    max-width: 720px;
    height: 100%;
    .checkbox-field-item {
      margin-bottom: 40px;
    }
  }
}
