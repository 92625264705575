@import '../styles/mixins';

.viewcontainer {
  min-width: 100%;
  width: 100%;
  min-height: calc(100vh - 50px); // -50px for nav bar
  position: absolute;
  top: 50px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .horizontalgrouping {
    width: 100%;
  }
  .header {
    height: 260px;
    width: 100%;
    padding-bottom: 5px;
    display: block;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 150;
    .banneraccent {
      top: 0;
      margin-left: 420px;
      max-height: 260px;
      position: absolute;
      .cls-1 {
        fill: none;
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        clip-path: url(#clip-path);
        height: 260px;
        fill: #004e9e;
      }
      .cls-4 {
        animation: myanimation 30s infinite;
        height: 260px;
      }
    }
    .bannerlogo {
      height: inherit;
      top: 15px;
      left: 10px;
      position: absolute;
      max-height: 220px;
    }
    .bannerplus {
      position: absolute;
      height: 263px;
      top: 0;
      left: 0;
    }
    .bannersponsor {
      position: absolute;
      height: 263px;
      margin-right: 30px;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      .text {
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        color: white;
        font-size: 12px;
        line-height: 100%;
      }
      img {
        width: 88px;
        height: 88px;
        margin-top: -20px;
      }
    }
    .textcontainer {
      position: relative;
      top: 0;
      left: 0;
      height: inherit;
      width: inherit;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 5px;
      .text {
        max-width: 500px;
        color: white;
        font-size: 36px;
        text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.3);
      }
    }

    &.homepagebanner {
      max-width: 1500px;
    }
  }
  .children {
    padding: 0 5px 0 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    & > .contentcontainer > div > div:not(.subnav) {
      animation: fadeIn 0.3s;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 740px) {
  .banneraccent {
    margin-left: 170px !important;
  }
}

.contentcontainer {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 1200px;
  flex: 0 1 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
}

@keyframes myanimation {
  15% {
    fill: #004e9e;
  }
  48% {
    fill: #00a651;
  }
  81% {
    fill: #ee334e;
  }
  100% {
    fill: #004e9e;
  }
}
