@import './../../styles/mixins';
@import './../../styles/colors';

.development-item-props-form {
  .formlabel {
    margin-top: 25px;
  }
  .form-group {
    margin-bottom: 5px;
  }
  .field, .formfield, .form-control {
    * {
      color: #4A4A4A;
    }
    .Select-value-label {
      color: #4A4A4A !important;
    }
    ::placeholder, ::-webkit-input-placeholder {
      font-size: 16px;
    }
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 2px;
    border-color: #808080;
    .formlabel {
      text-transform: uppercase;
      color: $deep-sea-blue;
      font-size: 16px;
    }
    input {
      border: 1px solid #808080;
      height: 60px;
      width: 100%;
      padding: 15px;
      font-size: 16px;
    }
    textarea {
      resize: none;
      height: 5em;
    }
    textarea::placeholder {
      font-size: 16px;
    }
    .addnewrowlink {
      height: 0;
      z-index: 10;
      align-self: flex-end;
      color: $deep-sea-blue;
      font-size: 16px;
      span {
        color: $deep-sea-blue;
      }
    }
  }
  .Select-control {
    font-size: 16px;
    height: 46px;
    border-radius: 2px;
    border: 1px solid #808080;

    .Select-value, .Select-placeholder {
      margin-top: 5px;
      font-size: 16px;
    }
  }
  .Select-menu-outer {
    z-index: 20;
  }

  .statuschoice {
    display: flex;
    flex-direction: row;
    justify-items: center;

    img {
      margin-right: 10px;
      width: 25px;
    }
  }
  .suggestiontitle {
    font-size: 16px;
  }
  summary {
    text-transform: uppercase;
    color: $deep-sea-blue;
    font-size: 16px;
  }
  .responsibility-field {
    padding: 0
  }
  .add-responsibility-person-button {
    align-self: flex-end;
    background: none !important;
    border: none;
    padding: 0 !important;
    color: $deep-sea-blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 18px;
  }
  h1 {
    color: $deep-sea-blue;
    font-weight: 400;
  }
  h2 {
    font-size: 19px;
    color: $deep-sea-blue;
    text-transform: uppercase;
    margin-top: 40px;
  }
  hr {
    margin-top: 40px;
    border-color: #c2c2c2;
  }
  textarea {
    width: 100%;
  }
  width: 100%;
  height: 100%;

  .pathtypeselectionfield {
    .field {
      flex-direction: row;
    }
    .radio {
      margin: 0;

      input {
        opacity: 0;
        position: fixed;
        width: 0;
      }
      label {
        padding: 10px 0;
        font-size: 16px;
        border-style: solid solid solid none;
        border-width: 1px;
        border-color: #808080;
        border-radius: 0;
        width: 100%;
        text-align: center;
        text-transform: none;
      }
    }
    span:first-child > .radio > label {
      border-style: solid;
      border-radius: 2px 0 0 2px;
    }
    span:last-child > .radio {
      margin-right: 1px;
      label {
        border-radius: 0 2px 2px 0;
      }
    }
    .unchecked, .checked {
      height: 100%;

      label {
        height: 100%;
      }
    }
    .unchecked > label {
      background: white;
      font-weight: normal;
    }
    .checked > label {
      background: #29A1D5;
      font-weight: bold;
      color: white;
    }
  }
  .attachment-field {
    .attachment * {
      color: $deep-sea-blue !important;
    }

    .placeholder {
      color: #4A4A4A;
    }

    .header {
      display: none;
    }

    .attachment-list {
      margin-bottom: 10px;
    }

    .add-attachments > .text {
      font-size: 16px;
      font-weight: normal;
      color: $deep-sea-blue;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    button {
      width: 240px;
      max-width: 350px;
    }
    .close-button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    button {
      width: 200px;
    }
  }
}

.attachment-modal {
  .modal-content {
    min-width: 600px;
  }
}