@import './../../styles/colors';

.development-item-deletion-modal {

  .modal-content {
    background-color: #e5e5e5;
    height: 100%;

    .modal-body {
      h1 {
        margin: 15px 30px 15px 30px;
        color: $deep-sea-blue;
        font-size: 30px;
      }
    }
    .modal-footer {
      .modalbuttons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        button {
          min-width: 220px;
        }

        padding-bottom: 30px;
      }
    }
  }
}
