@import '../../styles/colors';

.clubdashboard {
  min-height: 70vh;
}

.toolspage {
  margin-top: 40px;
  padding: 8px;
  .parts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .title {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .theme {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .title {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 18px;
      }
      a {
        margin-left: 15px;
        padding: 4px 0;
        font-size: 16px;
        color: $deep-sea-blue;
      }
    }
  }
}

.helpLinkspage {
  margin-top: 20px;
  padding: 8px;
  .helptext {
    margin: 15px 15px 40px 15px;
    font-size: 20px;
    color: $greyish-brown;
  }
  .contentful-helpLinks {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    a {
      margin-left: 15px;
      padding: 4px 0;
      font-size: 16px;
      color: $deep-sea-blue;
    }
  }
}

.pathspage {
  min-height: 70vh;
  h1 {
    text-align: center;
    color: $greyish-brown;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  .development-link {
    color: $deep-sea-blue;
    margin-bottom: 50px;
  }
}

.partcontainer {
  h1 {
    text-align: center;
  }
  padding: 5px 0px 100px 0px;
  .description ul {
    margin-top: -5px;
    padding-inline-start: 20px;
  }
}

.partcontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  p {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin: 20px 0;
  }
  .subtitle {
    margin-bottom: 20px;
    font-size: 16px;
  }
  form {
    width: 100%;
  }
  .tools {
    margin: 20px 0;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h4 {
      font-weight: bold;
      font-size: 19px;
    }
    a {
      color: $deep-sea-blue;
      margin: 5px 0;
      font-size: 16px;
    }
  }
}

.clubnewspage {
  margin-bottom: 50px;
  .startcontainer {
    padding: 5px;
    color: $greyish-brown;
    img {
      margin: 20px 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .noenrollment {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .text {
        width: 100%;
        max-width: 800px;
        text-align: center;
        .startheader {
          margin-bottom: 20px;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0;
        font-size: 16px;
        line-height: 20px;
      }
      a {
        color: $deep-sea-blue;
        font-weight: bold;
      }
      button {
        margin-bottom: 30px;
      }
    }
  }
  .markdownbox {
    flex: 1 1 45%;
    margin: 0 10px;
    h1 {
      color: $deep-sea-blue;
      font-weight: bold;
    }
    .markdown {
      border-radius: 3px;
      background-color: #f1fafa;
      padding: 20px;
      margin-top: 10px;
      min-height: 300px;
      font-size: 16px;
      a {
        color: $deep-sea-blue;
      }
    }
  }
  .news {
    min-width: 420px;
    flex: 1 1 90%;
  }
  .eventsimpactcontainer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .events {
      min-width: 420px;
    }
    .impact {
      min-width: 420px;
    }
  }
}

.summarybuttons {
  display: flex;
  justify-content: center !important;
}

.formcopycontainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;

  .formcopyinnercontainer {
    max-width: 365px;
    flex-direction: column;

    .formcopybuttonnote {
      font-size: 14px;
      padding-bottom: 15px;
    }
  }
}
