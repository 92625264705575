@import "colors";
@import "mixins";

.radio-button-as-button {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  height: 32px;

  .label-first {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .label-last {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.radio-button-as-button input[type=radio] {
  display: none;
}

.radio-button-as-button label {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  border: solid 1px $warm-grey;
  border-radius: 2px;
  box-shadow: none;
  padding: 6px 16px;
  letter-spacing: 0.5px;
  margin-right: -5px;
  margin-bottom: 0;
  color: #4a4a4a;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;

  &.modal-input {
    border: none !important;
  }

  &.radio-button-disabled {
    cursor: not-allowed;
    color: #808080;
    border-color: lighten(#808080, 20%);
    background-color: #f0f0f0;
  }
}

.radio-button-as-button input:checked + label {
  background-color: $dark-sky-blue-two !important;
  color: #ffffff;

  &.radio-button-disabled {
    cursor: not-allowed;
    background-color: darken(#f0f0f0, 20%) !important;
  }
}

.totalsponsorshipamount {
  .inlinecurrencyfield {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-width: none;
    flex: 0 1 65%;
    .currencyfield {
      width: 90%;
      max-width: none;
    }
    .currency {
      margin: 8px 0px 5px 0px;
    }
  }
}

.form-control[disabled] {
  background-color: #f8f8f8 !important;
  color: $text-color;
}

input[type="text"], textarea {
  &.form-control[disabled] {
    cursor: text;
  }
}
