
.development-item-card-frame {
  border: 1px solid #BAE9E8;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  background-color: white;
  overflow: hidden;

  .colorband {
    width: 10px;
    min-width: 10px;
  }

  .colorband-passive {
    background-color: #F2F2F2;
  }

  .colorband-notstarted {
    background-color: #E8F6F6;
  }

  .colorband-ongoing {
    background-color: #BAE9E8;
  }

  .colorband-ready {
    background-color: #00B7C6;
  }
}
