/**
* Colors for the application
*/
$deep-sea-blue: #00558b;
$bluish: #297fb8;
$dark-sky-blue: #3297dd;
$dark-sky-blue-two: #29a1d5;
$ocean-blue: #0176a8;
$light-blue: #5dc9e6;
$light-blue-grey: #bae9e8;
$light-light-blue: #dff4fa;
$tealish: #23beb8;
$black: #343434;
$white: #d8d8d8;
$white-two: #f0f0f0;
$white-three: #fff;
$warm-grey: #898989;
$pale-grey: #f1fafa;
$greyish-brown: #4a4a4a;
$orange: #e67f22;
$orange-two: #d35500;
$orange-three: #f68d2e;
$primary-color: #343434;
$secondary-color: #898989;
$disabled: #898989;
$placeholder: #898989;
$label-color: #898989;
$error-color: #d81a1a;
$lipstick: #bc204b;
$homesubgrey: #5a5a5a;