.app {
  display: flex;
  flex: 1 1 100%;
  min-height: 100vh;
}

.inner {
  margin-top: 50px;
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
}

/*
React Transition Group CSSTransition animations.
CSSTransition component uses these to provide css animations whenever route is changed
 */

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.fade-leave {
  transition: 0s;
}

.s-alert-wrapper {
  z-index: 10000; /* on top of greyed out modal background */

  .alertcontainer {
    margin: 5px 0;
    height: unset;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin-top: 50px; /* make sure the alerts don't overlap with the modals */
    margin-bottom: 50px; /* make sure the alerts don't overlap with the modals */
  }
}
