@import '../../styles/colors';
.auditgraphssection {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .auditgraphs {
    color: $greyish-brown;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  .recharts-tooltip-wrapper {
    transition: 50ms;
    z-index: 1000;

    .emptytooltip {
      margin: 0;
      padding: 10px;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(204, 204, 204);
      color: black;
      white-space: nowrap;
    }
  }

  .grades {
    margin: 30px 0;
    padding-left: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;

    span {
      margin-right: 10px;
      font-size: 11px;
      color: $greyish-brown;
    }

    .grade {
      display: flex;
      flex-direction: row;
      align-items: center;

      .colorball {
        height: 11px;
        width: 11px;
        min-width: 11px;
        border-radius: 100%;
        border: 1px solid black;
        margin-right: 10px;
      }

      .gradetype {
        font-size: 11px;
        color: $greyish-brown;
        margin-right: 10px;
      }
    }
  }
  .graphsegmentcontainer {
    margin: 0;
    padding: 0;
    position: relative;
    align-self: stretch;
    border-right: 1px solid #bae9e8;
  }

  .graphsegmentcontainer:last-child { border-right: none; }

  .graphsegment {
    margin: 20px 19px 20px 19px;

    .summarytext {
      margin-top: 32px;
      font-size: 11px;
      line-height: 15px;
      padding: 0 3px;
    }

    &:first-child {
      border-left: none;
    }

    width: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .horizontalbar {
      width: 200px;
      margin-top: 10px;

      span {
        margin-left: 4px;
        font-size: 12px;
      }

      .bartitle {
        display: block;
      }

      .recharts-responsive-container {
        margin-top: -5px;
      }
    }

    .donutchart {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .donuttitle {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
      }

      .donuttitlelink {
        color: $deep-sea-blue;
        cursor: pointer;
      }

      .donuticon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 70px;
        left: 70px;
      }

      .donuticonlink {
        cursor: pointer;
      }
    }
  }
}
