@import '../../styles/colors';
@import '../../styles/mixins';

.subnav {
  padding-top: 5px;
  ul {
    li {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a:hover {
        text-decoration: none !important;
      }
      a:link {
        text-decoration: none !important;
      }
    }
    margin: 0;
    padding: 0 5px 5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
  }
  .navigationlink {
    transition: 0.5s;
    display: flex;
    align-items: center;
    height: 52px;
    a {
      transition: 0.5s;
      padding: 15px 15px 8px 15px !important;
      color: $deep-sea-blue !important;
      border-bottom: 2px solid white;
      height: initial;
    }
    &.active {
      a {
        background-color: inherit !important;
        border-bottom: 2px solid $deep-sea-blue;
      }
    }
    &:hover {
      background-color: #f9f9f9;
      a {
        border-bottom: 2px solid #f9f9f9;
      }
      &.active {
        a {
          border-bottom: 2px solid $deep-sea-blue;
        }
      }
    }
  }
}
