.club-page-header-container {
  width: 100%;
  text-align: center;

  .club-page-header-club-name {
    width: 100%;
    margin-top: 30px;
  }

  .club-page-header-federation-name {
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    color: #898989;
  }
}