.development-item-props-modal {
  width: 600px;
  margin-top: auto;
  margin-bottom: auto;

  .modal-body {
    width: 100%;
    padding: 15px 0;
  }
  .modal-content {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #e5e5e5;
    width: 100%;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;

    h1 {
      font-size: 30px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.attachment-modal {
  margin-top: auto;
  margin-bottom: auto;

  .modal-content {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
