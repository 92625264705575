.clubpagecontainer {
  width: 100%;
  padding-top: 20px;
}

.top-sports-revoke-club-capability {
  display: flex;
  width: 100%;
}

.top-sports-club-permission-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 375px;
  min-width: 565px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 50px;

  .top-sports-club-permission-modal-headline {
    font-size: 30px;
    color: #00558B;
    padding-left: 36px;
    padding-right: 36px;
  }

  .top-sports-club-permission-modal-info-text {
    font-size: 18px;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
  }

  .top-sports-club-permission-modal-button-container {
    display: flex;
    justify-content: space-between;
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  .top-sports-club-permission-modal-button {
    max-width: 240px;
    min-width: 100px;
    max-height: 50px;
  }
}

.top-sports-capability-modal-button {
  color: #337ab7;
  font-size: 15px;
  &:hover {
    cursor: pointer;
  }
}