@import './../../styles/mixins';
@import './../../styles/colors';

.settings-modal {
  transition: 0.3s;
  /* Customize the label (the container) */
  .container {
    margin-top: 5px;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    line-height: 25px;
    color: $greyish-brown;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  max-width: 600px;
  padding: 20px;

  .settings-header {
    height: 50px;
    border-bottom: 2px solid darkgrey;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;

    .leftspans {
      margin-left: 20px;
    }

    span {
      margin: 0;
      font-size: 14px;
      padding: 10px 15px 10px 3px;
    }

    .closeicon {
      cursor: pointer;
      transition: 0.3s;
      border-radius: 3px;
      padding: 10px 10px;

      &:hover {
        background-color: rgb(190, 190, 190);
      }
    }

    .hoverbg {
      transition: 0.3s;
      border-radius: 3px;

      &:hover {
        background-color: rgb(190, 190, 190);
      }
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 5px;

    .modal-body {
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0;

      .clubinfocontainer {
        width: 100%;
        padding: 20px 30px 1px 30px;
        animation: fadeIn 0.5s;

        h1 {
          color: $deep-sea-blue;
          font-weight: bold;
          font-size: 24px;
          margin: 10px 0;
        }
        h3 {
          font-size: 18px;
          color: $deep-sea-blue;
          margin: 10px 0;
        }
        h4 {
          font-size: 14px;
          color: $deep-sea-blue;
          margin: 10px 0;
        }
      }

      .unlockcontainer {
        width: 100%;
        padding: 10px 30px;
        animation: fadeIn 0.5s;

        .title {
          margin-top: 20px;

          .status {
            font-weight: bold;
          }
        }

        button {
          margin: 20px 0;
          width: 100%;
        }

        .disclaimer {
          margin-bottom: 20px;
          font-size: 13px;
        }

        border-bottom: 2px solid darkgray;
      }

      .deadlinecontainer {
        width: 100%;
        padding: 10px 30px 0px 30px;
        animation: fadeIn 0.5s;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        h2 {
          font-size: 18px;
          color: $deep-sea-blue;
          margin: 20px 0px 10px 0px;
        }

        .deadlinedate {
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          .title {
            display: inline;
            padding-right: 15px;
          }

          input {
            width: 150px;
          }

          img {
            height: 20px;
            width: 20px;
            margin-left: 10px;
            background-color: transparent;
          }

          button {
            flex-grow: 1;
            min-width: 135px;
            padding: 0;
            margin-left: 20px;
          }
        }
      }

      .buttoncontainer {
        padding: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        flex-wrap: wrap;

        button {
          width: 100%;
          padding: 0;
        }

        padding-bottom: 30px;
      }
    }
  }
}

.confirmation-modal {
  width: 500px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
