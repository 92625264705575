.development-item-menu {
  .menu-btn {
    width: unset;
    height: unset;
    min-width: unset;
    min-height: unset;
    margin-top: 10px;
    margin-bottom: auto;
  }

  .dropdown-menu {
    position: relative;
    display: block;

    a {
      outline: none;
      padding-left: 0;
      display: flex;
      flex-direction: row;

      img {
        margin-left: 10px;
      }

      .menutext {
        margin-left: 10px;
      }
    }

    hr {
      margin: 5px 0 5px 0;
    }
  }
}

.lockedicon {
  margin: auto 5px auto 0;
}
