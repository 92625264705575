.auditTable {
  * {
    outline: none;
  }
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  .headerRow {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #29a1d5;
  }
  .auditRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .name-column-no-icon {
    height: 20px;
    color: #00558b;
    cursor: pointer;
    padding-right: 0px !important;
    & > div {
      padding-left: 14px;
    }
  }
  .namecolumn {
    background: url('../../images/report-icon.svg') no-repeat 6px 50%;
    height: 20px;
    color: #00558b;
    cursor: pointer;
    padding-right: 0px !important;
    padding-left: 28px;
    & > div {
      padding-left: 0px;
    }
  }
  .non-link-name-column {
    padding-left: 28px;
  }
  .finalizedname {
    padding-left: 0px !important;
    .namecolumn {
      & > div {
        padding-left: 0px;
      }
    }
  }
  .oddRow {
    background-color: #f1fafa;
  }
  .auditTableHeader {
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    padding: 14px;
    cursor: pointer;
  }
  .tablecolumn {
    padding: 3px 14px;
    height: 100%;
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .emptyRow {
    width: 100%;
    text-align: center;
    padding: 14px;
    font-size: 12px;
    background-color: #f1fafa;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .parts {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .partcircle {
      height: 24px;
      width: 24px;
      border-radius: 24px;
      margin: 2px;
    }
    .ready {
      background-color: #00a652;
      border: 1px solid #00a652;
    }
    .draft {
      border: 1px solid #00a652;
    }
    .not {
      border: 1px solid lightgrey;
      background-color: lightgrey;
    }
    .revision {
      border: 1px solid #faa61a;
      background-color: #faa61a;
    }
  }
}

.attachments-table {
  * {
    outline: none;
  }
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  .attachments-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
  }
  .header-row {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #29a1d5;
  }
  .non-link-name-column {
    padding-left: 28px;
  }
  .odd-row {
    background-color: #f1fafa;
  }
  .attachments-table-header {
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    padding: 14px;
    cursor: pointer;
  }
  .tablecolumn {
    padding: 3px 14px;
    height: 100%;
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .empty-row {
    width: 100%;
    text-align: center;
    padding: 14px;
    font-size: 12px;
    background-color: #f1fafa;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .parts {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .partcircle {
      height: 24px;
      width: 24px;
      border-radius: 24px;
      margin: 2px;
    }
    .ready {
      background-color: #00a652;
      border: 1px solid #00a652;
    }
    .draft {
      border: 1px solid #00a652;
    }
    .not {
      border: 1px solid lightgrey;
      background-color: lightgrey;
    }
    .revision {
      border: 1px solid #faa61a;
      background-color: #faa61a;
    }
  }
}

.show-unused-attachments {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  font-weight: bold;
  font-size: small;

  margin-top: 20px;
  margin-bottom: 20px;

  .styledcheckbox {
    margin: 0;
  }

  .checkboxfieldlabel {
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.reddate {
  color: darkred;
}
