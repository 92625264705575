.container {
  display: flex;
  flex-direction: column;
  font-size: 18px;

  .infoLine {
    text-align: center;
  }

  .buttonsContainer {
    display: flex;
    // row-reverse so that the Cancel button can be placed first in DOM/tab order.
    flex-direction: row-reverse;
    gap: 20px;

    .buttonContainer {
      width: 200px;
      min-width: 100px;
      max-width: 300px;
      flex-grow: 1;
    }
  }
}
