.year-content {
  flex-grow: 1;
  background: #F1FAFA;
  border: 1px solid #BAE9E8;
  border-bottom: 0;
  padding: 15px 0 15px 15px;
  overflow-y: auto;
  margin-right: 0;

  .year-view-theme {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;

    .theme-box {
      display: grid;
      margin-right: 0;
      grid-template-columns: repeat(auto-fill, 305px);
      grid-auto-rows: 5px;
      grid-auto-flow: row;
      gap: 5px 10px;

      .development-item-card {
        grid-area: span 11 / span 1;
      }

      .development-item-card-prioritized {
        grid-area: span 15 / span 1;
      }
    }
  }

  .placeholder {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }
}

.year-content-dragover {
  background-color: #00B7C6;
}

.year-content-droppable {
  background-color: #BAE9E8;
}
