.extra-info-text-container {
  width: 100%;
  border: 1px solid #dff4fa;
  border-top: none;
  padding: 20px 43px;

  .extra-info-text-header {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    .extra-info-text-icon {
      height: 24px;
      width: 24px;
      margin-right: 21px;
    }
    .extra-info-text-heading {
      font-size: 19px;
    }
  }
  .extra-info-text-content {
    width: 100%;
    margin-top: 20px;
    padding-left: 45px;
  }
}