.servicenotification {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4dc42;
  position: relative;
  bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 150;

  .notificationcontent {
    font-size: 20px;
    margin: 0 20px 0 20px;
    text-align: center;
  }
}
