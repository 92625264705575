@import 'src/styles/colors';
@import 'src/styles/mixins';

.development, .development-summary {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  * {
    color: #4A4A4A;
  }

  * > a {
    color: $deep-sea-blue;
  }

  .btn-secondary-blue {
    color: white;
  }

  h1 {
    text-align: center;
    font-weight: normal;
    font-size: 36px;
  }

  h3 {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }

  .development-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 880px;

    h3  {
      width: 700px;
    }

    .text {
      width: 100%;
      max-width: 880px;
      text-align: center;
      margin-top: 30px;
      font-size: 13px;

      .three-steps {
        display: flex;
        flex-direction: row;

        .step {
          flex-grow: 1;
          text-align: left;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 30%;
          font-size: 11px;

          img {
            margin-right: 15px;
            min-width: 70px;
            min-height: 70px;
          }
        }

        .step:not(:last-child) {
          margin-right: 20px;
        }
      }

      .vertical-divider {
        margin: 40px 0 30px 0;
      }
    }

    .description {
      width: 840px;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      @include link;
      background: none;
      border: none;
      outline: 0;
      margin: 0;
      padding: 0;
      letter-spacing: revert;
    }
  }

  .development-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: min(70vh, 1000px);

    .year-view {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-right: 35px;

      .year-tab-row {
        display: flex;
        flex-direction: row;
        margin-left: -35px;
        margin-right: -35px;
        height: 70px;
        min-height: 70px;

        .arrow {
          width: 35px;
          padding: 0;
        }
        .tabs {
          display: flex;
          flex-direction: row;
          flex-grow: 1;
        }
      }
    }
  }

  .filter-row {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .vertical-divider {
    width: 100%;
    height: 1px;
    margin-top: 0;

    hr {
      height: 100%;
      margin: 0;
      border: none;
      color: #BAE9E8;
      background-color: #BAE9E8;
    }
  }

  .summary-button {
    width: 300px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 50px;
    button {
      width: 200px;
    }
  }

  .development-item-list-theme {
    > * {
      margin: 10px 10px 10px 0;
    }

    .placeholder {
      margin: 20px 0 30px 5px;
    }

    .addnewitem {
      border: 1px solid #BAE9E8;
      border-radius: 5px;
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 30px 0;

      .newitembtn {
        width: 100%;
        color: $deep-sea-blue;
        border: none;
      }
    }
  }

  .part-title {
    margin: 10px 0 10px 0;
    font-size: 16px;
  }

  .theme-title {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .back-link {
    color: $deep-sea-blue;
    align-self: flex-start;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: bold;
  }
}

.development-summary {
  min-height: 1300px;

  h1 {
    margin-top: 0;
  }
}

.btn-transparent {
  background: none;
  border: none;
}

.development-summary-table {
  display: flex;
  flex-direction: row;
  margin-top: 60px;

  .trophy-box {
    width: 280px;
    height: 122px;
    margin-right: 20px;

    .trophy-box-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .ready {
        font-size: 20px;
      }
      .trophy {
        font-size: 36px;
        font-weight: bold;
        margin-top: 5px;

        img {
          margin-right: 10px;
          margin-top: -12px;
        }
      }
    }
  }
  .number-box {
    width: 280px;
    height: 36px;
    margin-bottom: 7px;

    .number-box-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;

      .number-box-title {
        flex-grow: 1;
        margin-left: 10px;
      }
      .vertical-line {
        height: 100%;
        width: 1px;
        border-left: 1px solid #BAE9E8;
        justify-self: flex-end;
      }
      .number-box-result {
        justify-self: flex-end;
        width: 40px;
        text-align: center;
      }
    }
  }
}

.development-summary-content {
  display: flex;
  flex-direction: column;
  border: 1px solid #BAE9E8;
  border-radius: 5px;
  min-height: 150px;
  width: 100%;

  > * {
    margin: 20px;
  }

  .part-section {
    margin-left: 20px;
    margin-bottom: 30px;

    .part-title {
      font-size: 19px;
      font-weight: bold;
      margin-top: 10px;

      .custom-part-title {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .num-part-items {
          font-size: 16px;
          font-weight: normal;
          margin-left: 30px;
        }
      }
    }

    .theme-section {
      margin: 20px 0 0 30px;

      .theme-title {
        color: #808080;
        font-size: 16px;
        margin: 0;
      }
    }
  }

  .development-item-list-theme {
    display: flex;
    flex-direction: column;

    > * {
      margin: 10px 0 0 0;
    }

    .placeholder {
      margin-left: 30px;
    }

    .newitembtn {
      width: unset;
      min-height: unset;
      margin-left: auto;
      color: $deep-sea-blue;
      border: none;
    }
  }
}